import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Layout, SectionGrid } from "../components/common";
import { MetaData } from "../components/common/meta";
import * as tagpageStyles from '../styles/tagpage.module.css'
import { CryptoIcon, PoliticsIcon, ScienceIcon, TechIcon } from "../assets/Index";

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({ data, location }) => {
    const tag = data.ghostTag;
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData data={data} location={location} type="series" />
            <Layout>
                <div className={tagpageStyles.tagpage}>
                    <header className={tagpageStyles.header}>
                        {
                            tag.name === 'tech'
                            ? <TechIcon />
                            : tag.name === 'crypto'
                            ? <CryptoIcon />
                            : tag.name === 'politica'
                            ? <PoliticsIcon />
                            : tag.name === 'ciencias'
                            ? <ScienceIcon />
                            : ''
                        }
                        <h1 className={tagpageStyles.title}>{tag.name === 'politica' ? 'política' : tag.name}</h1>
                    </header>
                    <SectionGrid tag={tag.name} posts={posts} context={'tagpage'} />
                </div>
            </Layout>
        </>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Tag;

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
